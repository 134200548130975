import { registerApplication, start } from 'single-spa';
import {
  constructApplications,
  constructRoutes,
  constructLayoutEngine
} from 'single-spa-layout';
import { getMsalInstance } from '@bayshoreHealthCare/store';
import microfrontendLayout from './microfrontend-layout.html';
import './style.css?modules=false';

const spinner = ` <style>
.loader {
  border: 5px solid #f3f3f3;
  border-radius: 50%;
  border-top: 5px solid #0b435b;
  width: 60px;
  height: 60px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
</style>
<div style="display:flex;flex:1;flex-grow:1 ;justify-content:center; align-items:center;">
<div class="loader"></div> 
</div>
`;

const customLayoutProps = {
  loaders: {
    spinner
  },
  props: {}
};

const routes = constructRoutes(microfrontendLayout, customLayoutProps);
const applications = constructApplications({
  routes,
  loadApp({ name }) {
    return System.import(name);
  }
});
const layoutEngine = constructLayoutEngine({ routes, applications });
applications.forEach(registerApplication);
const msalInstance = getMsalInstance();

if (!msalInstance.getActiveAccount() && msalInstance.getAllAccounts().length > 0) {
  // Account selection logic is app dependent. Adjust as needed for different use cases.
  msalInstance.setActiveAccount(msalInstance.getActiveAccount()[0]);
}

const activeAccount = msalInstance.getActiveAccount();

const handleNavBars = (location: Location) => {
  if (/^\/call/.test(location.pathname) && !activeAccount) { // Hiding top nav & sidebar for guestuser in call screen
    return false;
  }
  return !/^\/auth/.test(location.pathname);
};

registerApplication(
  '@bayshoreHealthCare/nav-app',
  // @ts-ignore
  () => window.System.import('@bayshoreHealthCare/nav-app'),
  handleNavBars
);

registerApplication(
  '@bayshoreHealthCare/side-app',
  // @ts-ignore
  () => window.System.import('@bayshoreHealthCare/side-app'),
  handleNavBars
);

layoutEngine.activate();

start();